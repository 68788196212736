
import { Link } from "react-router-dom";

import React, { useContext } from 'react'
import { BasketContext } from "../../context/Context";

const DrawerCart = ({
  product,
}) => {
  const {
    removeAdd,
    decrement,
    increment,
    setIsBasketOpen,

  } = useContext(BasketContext)


  const defaultImage = '../../images/product-no-photo.png';

  let images = [];
  if (product.images && typeof product.images === 'string') {
    try {
      images = JSON.parse(product.images);
    } catch (e) {
      console.error('Ошибка при разборе JSON:', e);
    }
  }

  // Определяем, какое изображение использовать
  const imageUrl = images.length > 0 ? images[0] : defaultImage;
  
  return (
    <div className="drawer__cart">
      <div className="drawer__cart-img-block">
        <img
          width="200px"
          height="200px"
          decoding="async"
          src={imageUrl}
          className="drawer__cart-img"
          alt={product.med_name}
        />
      </div>
      <div className="drawer__cart-text">
        <Link
          aria-activedescendant={"Перейти до " + product.med_name}
          to={`/product/${product.med_id}`}
          onClick={() => setIsBasketOpen(false)
          }
        >
          <h4 className="drawer__cart-title">{product.med_name}</h4>
        </Link>
        <div className="drawer__cart-text-wrapper">
          <p className="drawer__cart-minus" onClick={(e) => decrement(e, product)}>
            -
          </p>
          <p className="drawer__cart-counter">{product.quantity ? product.quantity : 0}</p>
          <p className="drawer__cart-plus" onClick={(e) => increment(e, product)}>
            +
          </p>

          <p className="drawer__cart-price">
            {product.quantity ? product.price * product.quantity : product.price} ₴
          </p>
        </div>
      </div>

      <div
        onClick={() => removeAdd(product)}
        className="drawer__button-remove"
      ></div>
    </div>
  );
};
export default DrawerCart;


