import React, { useContext, useState } from 'react';
import { Navbar, Nav, Container, Form } from 'react-bootstrap';
import { FaShoppingCart, FaUser, FaSearch } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';

import AutocompleteInput from './AutocompleteInput';
import { BasketContext } from '../context/Context';

const Header = () => {
  const {
    setIsBasketOpen,
  } = useContext(BasketContext)
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (term) => {
    setSearchTerm(term);
    // Здесь можно выполнить действия, связанные с поиском
  };

  return (
    <header>
      <Navbar bg="light" variant="light" expand="lg" className="py-3 shadow-sm">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className="d-flex align-items-center">
              <span className="brand-text">aptekaLandysh</span>
            </Navbar.Brand>
          </LinkContainer>
          {/* <Navbar.Toggle aria-controls="navbarSearch" /> */}
          <Navbar id="navbarSearch" className="flex-grow-1">
            <Form className="d-flex w-100 search-form">
              <AutocompleteInput
                onSearch={handleSearch}
                className="flex-grow-1 "
                placeholder="Найти лекарство..."
              />
              <button type="button" className="btn btn-success search-button">
                <FaSearch />
              </button>
            </Form>
            <Nav className="ml-auto nav-block">
                  <FaShoppingCart onClick={()=>setIsBasketOpen(true)} style={{margin: 5, width:25,height:25, fill: "rgba( 24, 188, 156, 1)"}} />
              <a
                style={{
                  display: "flex",
                  alignItems: 'center'
                }}
                  href="https://t.me/astana22"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    width="100px"
                    height="100px"
                    decoding="async"
                    className="header__menu-social-img"
                    src={`${process.env.PUBLIC_URL}/images/telegram-svgrepo-com.svg`}
                    alt="mistergiften social"
                  />
                </a>
              {/* <LinkContainer to="/login">
                <Nav.Link>
                  <FaUser /> Войти
                </Nav.Link>
              </LinkContainer> */}
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;