import React, { useEffect } from "react";

const Oferta = () => {
  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    // Вызовите scrollToTop при загрузке страницы
    scrollToTop();
  }, []);

  return (
    <>
      <strong className="Oferta">
        ; ПУБЛИЧНЫЙ ДОГОВОР (ОФЕРТА)
        <br />
        на заказ, куплю-продажу и доставку товаров
        <br />
        <br />
        Этот договор является официальным и публичным предложением Продавца заключить
        договор купли-продажи Товара, представленного на сайте
        https://aptekalandysh.ru/. Данный договор является публичным, то есть в соответствии
        со статьей 633 Гражданского кодекса России, его условия одинаковы для
        всех покупателей независимо от их статуса (физическое лицо, юридическое лицо,
        физическое лицо-предприниматель) без предоставления преимущества одному покупателю перед
        другим. Заключая этот Договор, покупатель в полном объеме принимает
        условия и порядок оформления заказа, оплаты товара, доставки товара,
        возврата товара, ответственности за недобросовестный заказ и все
        другие условия договора. Договор считается заключенным с момента нажатия
        кнопки «Подтвердить Заказ» на странице оформления заказа в
        разделе «Корзина» и получения Покупателем от Продавца подтверждения
        заказа в электронном виде.
        <br />
        <br />
        1. Определение терминов
        <br />
        1.1. Публичная оферта (далее - «Оферта») - публичное предложение Продавца,
        адресованное неопределенному кругу лиц, заключить с Продавцом договор
        купли-продажи товара дистанционным способом (далее - «Договор») на
        условиях, содержащихся в этой Оферте.
        <br />
        1.2. Товар или Услуга – объект соглашения сторон, который был выбран покупателем
        на сайте Интернет-магазина и помещен в корзину, или уже приобретенный
        Покупателем у Продавца дистанционным способом.
        <br />
        1.3. Интернет-магазин – сайт Продавца по адресу
        https://aptekalandysh.ru/ созданный для заключения договоров розничной
        и оптовой купли-продажи на основании ознакомления Покупателя с
        предложенным Продавцом описанием Товара с помощью сети Интернет.
        <br />
        1.4. Покупатель – дееспособное физическое лицо, достигшее 18 лет, получающее
        информацию от Продавца, размещающее заказ на покупку товара, представленного на сайте Интернет-магазина для целей, не связанных с
        осуществлением предпринимательской деятельности, либо юридическое лицо или физическое лицо-предприниматель.
        <br />
        1.5. Продавец – «Mister Gifter», ФЛП, которая создана и действует в соответствии с
        действующим законодательством России.
        <br />
        <br />
        2. Предмет Договора
        <br />
        2.1. Продавец обязуется передать в собственность Покупателю Товар, а
        Покупатель обязуется оплатить и принять Товар на условиях этого
        Договора.
        <br />
        2.2. Датой заключения Договора-оферты (акцептом оферты) и моментом
        полного и безоговорочного принятия Покупателем условий Договора считается
        дата заполнения Покупателем формы заказа, расположенной на сайте
        Интернет-магазина, при условии получения Покупателем от Продавца
        подтверждения заказа в электронном виде. В случае необходимости, по
        желанию Покупателя, Договор может быть оформлен в письменной форме.
        <br />
        <br />
        3. Оформление Заказа
        <br />
        <br />
        3.1. Покупатель самостоятельно оформляет заказ в Интернет-магазине через
        форму «Корзина», или делая заказ по электронной почте или по номеру
        телефона, указанному в разделе контактов Интернет-магазина.
        <br />
        3.2. Продавец имеет право отказаться от передачи заказа Покупателю
        в случае, если данные, указанные Покупателем при оформлении
        заказа, являются неполными или вызывают подозрение в их достоверности.
        <br />
        3.3. При оформлении заказа на сайте Интернет-магазина Покупатель
        обязуется предоставить следующую обязательную информацию, необходимую
        Продавцу для выполнения заказа:
        <br />
        3.3.1. фамилия, имя Покупателя;
        <br />
        3.3.2. адрес, по которому следует доставить Товар (если доставка до адреса
        Покупателя);
        <br />
        3.3.3. контактный телефон.
        <br />
        3.3.4. Идентификационный код для юридического лица или физического лица-предпринимателя.
        <br />
        3.4. Наименование, количество, артикул, цена выбранного Покупателем Товара
        указываются в корзине Покупателя на сайте Интернет-магазина.
        <br />
        3.5. Если какой-либо из сторон договора необходима дополнительная информация,
        он имеет право запросить ее у другой стороны. В случае непредоставления необходимой информации Покупателем, Продавец не несет ответственности за предоставление
        качественной услуги Покупателю при покупке товара в интернет-магазине.
        <br />
        3.6. При оформлении заказа через оператора Продавца (п. 3.1. Настоящей
        Оферты) Покупатель обязуется предоставить информацию, указанную в п. 3.3 –
        3.4. настоящей Оферты.
        <br />
        3.7. Принятие Покупателем условий настоящей Оферты осуществляется путем
        внесения Покупателем соответствующих данных в регистрационную форму на сайте
        Интернет-магазина или при оформлении Заказа через оператора. После
        оформления Заказа через Оператора данные о Покупателе вносятся в базу
        данных Продавца.
        <br />
        3.8. Покупатель несет ответственность за достоверность предоставленной информации
        при оформлении Заказа.
        <br />
        3.9. Заключая Договор, то есть акцептуя условия данного предложения
        (предложенные условия приобретения Товара), путем оформления Заказа,
        Покупатель подтверждает следующее:
        <br />
        а) Покупатель полностью ознакомлен и согласен с условиями данного
        предложения (оферты);
        <br />
        б) он дает согласие на сбор, обработку и передачу персональных данных,
        разрешение на обработку персональных данных действует в течение всего срока действия
        Договора, а также в течение неограниченного срока после его
        окончания. Кроме того, заключением договора Покупатель подтверждает, что он
        уведомлен (без дополнительного уведомления) о правах, установленных
        Законом России "О защите персональных данных", о целях сбора данных, а
        также о том, что его персональные данные передаются Продавцу с целью
        возможности выполнения условий этого Договора, возможности проведения
        взаиморасчетов, а также для получения счетов, актов и других
        документов. Покупатель также соглашается с тем, что Продавец имеет право
        предоставлять доступ и передавать его персональные данные третьим лицам без
        каких-либо дополнительных уведомлений Покупателя с целью выполнения заказа
        Покупателя. Объем прав Покупателя, как субъекта персональных данных в соответствии
        с Законом России "О защите персональных данных" ему известен и
        понятен.
        <br />
        <br />
        <br />
        4. Цена и Доставка Товара
        <br />
        4.1 Цены на Товары и услуги определяются Продавцом самостоятельно и
        указаны на сайте Интернет-магазина. Все цены на Товары и услуги
        указаны на сайте в рублях с учетом НДС.
        <br />
        4.2 Цены на Товары и услуги могут изменяться Продавцом в
        одностороннем порядке в зависимости от конъюнктуры рынка. При этом цена
        отдельной единицы Товара, стоимость которой оплачена Покупателем в полном
        объеме, не может быть изменена Продавцом в одностороннем порядке.
        <br />
        4.3. Стоимость Товара, указанная на сайте Интернет-магазина, не включает
        стоимость доставки Товара Покупателю. Стоимость доставки Товара
        Покупатель оплачивает в соответствии с действующими тарифами служб доставки
        (перевозчиков) непосредственно выбранной им службе доставки (перевозчику).
        <br />
        4.4. Стоимость Товара, указанная на сайте Интернет-магазина, не включает
        стоимость доставки Товара на адрес Покупателя.
        <br />
        4.5. Продавец может указать ориентировочную стоимость доставки Товара на
        адрес Покупателя при обращении Покупателя с соответствующим запросом к
        Продавцу путем отправки письма на электронную почту или при оформлении
        заказа через оператора интернет-магазина.
        <br />
        4.6. Обязательства Покупателя по оплате Товара считаются выполненными с
        момента поступления Продавцу средств на его счет.
        <br />
        4.7. Расчеты между Продавцом и Покупателем за Товар осуществляются
        способами, указанными на сайте Интернет-магазина в разделе «Оплата и
        Доставка».
        <br />
        4.8. При получении товара Покупатель должен в присутствии представителя
        службы доставки (перевозчика) проверить соответствие Товара качественным и
        количественным характеристикам (наименование товара, количество,
        комплектность, срок годности).
        <br />
        4.9. Покупатель или его представитель при получении Товара подтверждает
        своей подписью в товарном чеке/ или в заказе/ или в транспортной
        накладной на доставку товаров, что не имеет претензий к количеству товара,
        внешнему виду и комплектности товара.
        <br />
        4.10. Право собственности и риск случайной утраты или повреждения Товара
        переходит к Покупателю или его Представителю с момента получения Товара
        Покупателем в месте поставки Товара при самостоятельной доставке Товара от
        Продавца, либо при передаче Продавцом товара службе доставки
        (перевозчику), выбранной Покупателем.
        <br />
        <br />
        5. Права и обязанности Сторон
        <br />
        5.1. Продавец обязуется:
        <br />
        5.1.1. Передать Покупателю товар в соответствии с условиями настоящего Договора
        и заказа Покупателя.
        <br />
        5.1.2. Не разглашать любую частную информацию о Покупателе и не
        предоставлять доступ к этой информации третьим лицам, за исключением случаев,
        предусмотренных законодательством и при выполнении
        <br />
      </strong>
        <title>Оферта</title>
    </>
  );
};

export default Oferta;
