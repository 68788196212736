import React, { useState, useEffect, createContext } from "react"
import Cookies from "js-cookie";

export const BasketContext = createContext()

const Context = ({ children }) => {
    const [classForText, setClassForText] = useState("TextShow");
    const [classAdd, setAddClass] = useState("LikeButton_animation");
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [addedCart, setAddedCarts] = useState(() => {
        const cartFromCookies = Cookies.get("addedCart");
        return cartFromCookies ? JSON.parse(cartFromCookies) : [];
    });
    const [userInputsData, setUserInputsData] = useState(() => {
        const userInputsFromCookies = Cookies.get("userInputsData");
        return userInputsFromCookies ? JSON.parse(userInputsFromCookies) : {
            fullName: {
                value: "",
                isValid: true,
                errorMessage: "",
            },
            phoneNumber: {
                value: "",
                isValid: true,
                errorMessage: "",
            },
            city: {
                value: "",
                isValid: true,
                errorMessage: "",
            },
            postBranch: {
                value: "",
                isValid: true,
                errorMessage: "",
            },
            comment: {
                value: "",
                isValid: true,
                errorMessage: "",
            },
        };
    }
    );



    useEffect(() => {
        isBasketOpen ? document.body.classList.add('body-active') : document.body.classList.remove('body-active');
    }, [isBasketOpen]);

    useEffect(() => {
        const cartFromCookies = Cookies.get("addedCart");
        const userInputsFromCookies = Cookies.get("userInputsData");

        if (cartFromCookies) {
            setAddedCarts(JSON.parse(cartFromCookies));
        } else if (userInputsFromCookies) {
            setUserInputsData(JSON.parse(userInputsFromCookies));
        }
    }, []);


    useEffect(() => {
        calculateTotalPrice();
        Cookies.set("addedCart", JSON.stringify(addedCart), { expires: 7 });
        Cookies.set("userInputsData", JSON.stringify(userInputsData), { expires: 7 });
        addButtonAnimation()
    }, [addedCart, userInputsData]);








    const calculateTotalPrice = () => {
        const total = addedCart.reduce((sum, item) => {
            return sum + (+item.price * +item.quantity);
        }, 0);
        setTotalPrice(total);
    };

    const addButtonAnimation = () => {

        setAddClass("LikeButton_animation");
        setClassForText("TextShow TextShow_active");
        setTimeout(() => {
            setAddClass((prevValue) => (prevValue = ""));
            setClassForText("TextShow");
        }, 500);
    }

    const onAdd = (e, updatedCard) => {
        e.preventDefault();
        setAddedCarts((prevData) => [...prevData, updatedCard]
        );
    };

    const increment = (e, cart) => {
        e.preventDefault();
        const addedQuantity = addedCart.find(i => i.med_id === cart.med_id).quantity
        const newCart = { ...cart, quantity: 1 + addedQuantity };
        console.log(cart, newCart)
        setAddedCarts((prevData) =>
            prevData.map((element) =>
                element.med_id === newCart.med_id ? newCart : element
            )
        );
    };

    const decrement = (e, cart) => {
        e.preventDefault();
        const addedQuantity = addedCart.find(i => i.med_id === cart.med_id).quantity
        const newCart = { ...cart, quantity: addedQuantity - 1 };
        newCart.quantity === 0
            ? removeAdd(newCart)
            : setAddedCarts((prevData) =>
                prevData.map((element) =>
                    element.med_id === newCart.med_id ? newCart : element
                )
            );
    };

    const changeQuantity = (quantity, cart) => {
        const newCart = { ...cart, quantity: +quantity };
        if (+quantity > 0) {
            setAddedCarts((prevData) =>
                prevData.map((element) =>
                    element.med_id === newCart.med_id ? newCart : element
                )
            );
        } else {
            removeAdd(cart)
        }
    }

    const addAddedCart = (cart) => {
        setAddedCarts((prevData) => [...prevData, { ...cart, quantity: 1 }])
    }

    const removeAdd = (cart) => {
        setAddedCarts((prevData) => {
            const newArr = prevData.filter(
                (element) => element.med_id !== cart.med_id
            );
            if (newArr.length === 0) {
                console.log("lsdalj");
            }
            return newArr;
        });
    };

    const clearAddedCarts = () => {
        setAddedCarts([])
    }


    const basketContext = {
        addedCart,
        totalPrice,
        isBasketOpen,
        userInputsData,
        setUserInputsData,
        addAddedCart,
        setAddedCarts,
        onAdd,
        removeAdd,
        decrement,
        increment,
        changeQuantity,
        setIsBasketOpen,
        classForText,
        classAdd,
        clearAddedCarts
    }
    return <>
        <BasketContext.Provider value={basketContext}>
            {children}
        </BasketContext.Provider>
    </>
}
export default Context;