import DrawerCart from "../DrawerCart";
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { BasketContext } from '../../context/Context';
import SuccessModalMessage from "../SuccessModalMessage";
import sendMessageToTelegram from "../../utils/sendMessageToTelegram";
import genTelegramMessa from "../../utils/genTelegramMessa";
import sendDataToBack from "../../utils/sendDataToBack";
import axios from "axios";

const API_REQUEST_DELAY = 0;
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MIN_CITY_NAME_LENGTH = 3;

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
	if (timeoutId) {
	  clearTimeout(timeoutId);
	}
	timeoutId = setTimeout(() => {
	  func(...args);
	}, delay);
  };
};

const Basket = () => {
  const {
	addedCart,
	setIsBasketOpen,
	isBasketOpen,
	totalPrice,
	clearAddedCarts,
	userInputsData,
	setUserInputsData,
  } = useContext(BasketContext);

  const [pickupPoint, setPickupPoint] = useState([]);
  const [pickupPointsList, setPickupPointsList] = useState([]);

  let currPhoneNumber = "";

  const searchDeliveryCity = async (value) => {
	if(value.length < MIN_CITY_NAME_LENGTH) {
		setDeliveryCitiesList([]);
		setPickupPointsList([]);
		setActiveCity([]);
		setPickupPoint([]);
		setShowCities(false);
		setShowPickupPoints(false);
	  	return;
	}
	const url = `${REACT_APP_SERVER_URL}/api/orders/cities`;
	const response = await axios.get(url, {
	  params: {
		city: value 
	  }
	})

	console.log("Search city:", value)
	console.log("Cities result from backend:");
	console.log(response.data);
	const newData = response.data.map((city) => ({
	  ...city,
	  isActive: false,
	}));
	setDeliveryCitiesList(newData);
	setShowCities(true);
  }

  const searchPickupPoint = useCallback((inputValue) => {
	if (inputValue.trim() === '') {
	  setPickupPointsList(pickupPoint);
	  return;
	}

	const filteredData = pickupPoint
	  .filter(item =>
		item.location.address.toLowerCase().includes(inputValue.toLowerCase())
	  )
	  .slice(0, 5);

	setPickupPointsList(filteredData);
  }, [pickupPoint]);

  const debounceSearchDeliveryCity = useRef(debounce(searchDeliveryCity, API_REQUEST_DELAY));
  const debounceSearchPickupPoint = useRef(debounce(searchPickupPoint, API_REQUEST_DELAY));

  const [isShowMessage, setShowMessage] = useState(false)
  const [inputMessenger, setInputMessenger] = useState("telegram");
  const [isValid, setIsValid] = useState(true);
  const [deliveryCitiesList, setDeliveryCitiesList] = useState([]);

  const price = totalPrice
  const [activeCity, setActiveCity] = useState([]);
  const [isShowCities, setShowCities] = useState(false);
  const [isShowPickupPoints, setShowPickupPoints] = useState(true);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [deliveryCostError, setDeliveryCostError] = useState("");

  useEffect(() => {
	if (activeCity.length!==0) {
		console.log(activeCity)
	  	retrievePickupPoints(activeCity.code)
	}
  }, [activeCity])

  useEffect(() => {
	console.log(pickupPointsList)
  }, [setPickupPointsList])

  useEffect(() => {
	console.log(pickupPoint)
  }, [pickupPoint])

  useEffect(() => {
	debounceSearchDeliveryCity.current = debounce(searchDeliveryCity, API_REQUEST_DELAY);
  }, [searchDeliveryCity]);

  useEffect(() => {
	debounceSearchPickupPoint.current = debounce(searchPickupPoint, API_REQUEST_DELAY);
  }, [searchPickupPoint]);

  const retrievePickupPoints = async (value) => {
	const url = `${REACT_APP_SERVER_URL}/api/orders/deliverypoints`;
	const response = await axios.get(url, {
	  params: {
		cityCode: value 
	  }
	});

	const newData = response.data.map((warehouse) => ({
	  ...warehouse,
	  isActive: false,
	}));

	setPickupPoint(newData);
	setShowPickupPoints(true);
  };

  const fetchDeliveryCost = async (activeDepartment) => {
	const url = `${REACT_APP_SERVER_URL}/api/orders/tariffs`;
	const response = await axios.get(url, {
	  params: {
		postalCode: activeDepartment,
		cityName: activeCity.city
	  }
	});

	const data = response.data;
	if (response.status == 200 && data.total_sum) {
	  setDeliveryCost(data.total_sum);
	  setDeliveryCostError("");
	} else {
	  setDeliveryCostError("Unable to calculate delivery cost. Please try again.");
	}
  };

  const onChangeInput = (
	even
  ) => {
	let { name, value } = even.target;
	let isValid = true;
	let errorMessage = "";
	value = value.trim()
	switch (name) {
	  case "fullName":
		value.length > 5 ? (isValid = true) : (isValid = false);
		errorMessage = isValid ? "" : "Минимальная длина 5 букв";
		break;
	  case "phoneNumber":
		value = handleInputChange(value);
		value.length === 18 ? (isValid = true) : (isValid = false);
		errorMessage = isValid ? "" : "Введите правильно номер телефона";
		break;
	  case "city":
		value.length >= MIN_CITY_NAME_LENGTH ? (isValid = true) : (isValid = false);
		debounceSearchDeliveryCity.current(value); // Викликаємо з дебаунсо
		errorMessage = isValid ? "" : "Минимальная длина 3 буквы";
		if(!isValid) {
		  setDeliveryCitiesList([]);
		}
		break;
	  case "postBranch":
		value.length > 0 ? (isValid = true) : (isValid = false);
		searchPickupPoint(value) // Викликаємо з дебаунсо

		errorMessage = isValid ? "" : "Выберите номер отделения";
		//fetchDeliveryCost(); // Fetch delivery cost when city or warehouse is selected
		break;
	  default:
		break;
	}
	setUserInputsData((prevData) => ({
	  ...prevData,
	  [name]: {
		value,
		isValid,
		errorMessage,
	  },
	}));
  };

  const handleInputChange = (e) => {
	const value = e;

	// replace invalid characters
	let numericValue = value.replace(/\D/g, '');
	
	// remove leading zeros
	numericValue = numericValue.replace(/^0+/, '');

	// add the country code in front
	if (!numericValue.startsWith('7')) {
	  numericValue = '7' + numericValue;
	}

	let processed = "+7";
	
	// process the remaining numbers
	numericValue = numericValue.substring(1, 11);

	if(numericValue.length > 0) {
		processed += " (" + numericValue.substring(0, 3);
		if(numericValue.length >= 4) {
			processed += ") " + numericValue.substring(3, 6);
			if(numericValue.length >= 7) {
				processed += "-" + numericValue.substring(6, 8);
				if(numericValue.length >= 9) {
					processed += "-" + numericValue.substring(8, 10);
				}
			}
		}
	}

	return processed;
  };

  const onSubmitFunc = async (e) => {
	e.preventDefault();
	let status = false;
	const newArr = {};
	const dataforServices = {
	  fullName: userInputsData.fullName.value,
	  phoneNumber: userInputsData.phoneNumber.value,
	  city: userInputsData.city.value,
	  postBranch: userInputsData.postBranch.value,
	  comment: userInputsData.comment.value,
	  carts: addedCart,
	  inputMessenger,
	  total: totalPrice
	}
	console.log(dataforServices)
	for (const fieldName in userInputsData) {
	  const element = (userInputsData)[fieldName];
	  if (fieldName !== "comment") {
		element.value.length === 0 && element.isValid
		  ? (element.isValid = false)
		  : console.log("ele")
	  }
	  (newArr)[fieldName] = element;
	}
	const areSomeHaveValue = Object.values(userInputsData).some((file) => {
	  return file.value.length > 0;
	});
	if (areSomeHaveValue) {
	  status = Object.values(userInputsData).every((field) => field.isValid);
	}
	setUserInputsData(newArr);
	setIsValid(status);
	const telegramText = await genTelegramMessa(dataforServices)

	if (status) {
	  await sendMessageToTelegram(telegramText);
	  await sendDataToBack(dataforServices)
	  clearAddedCarts();
	  setShowMessage(true);
	  setIsBasketOpen(false);
	  return;
	}
  };

  const closeMenu = (event) => {
	const clickedElement = event.target;
	if (clickedElement.className === "drawer drawer--active") {
	  setIsBasketOpen(false)
	} else if (clickedElement.className === "ModalMessage") {
	  setShowMessage(false);
	} 
  };

  const numberOfCartItems = addedCart?.length;

  return (
	<>
	  {!isShowMessage ? (
		<section
		  className={isBasketOpen ? "drawer drawer--active" : "drawer"}
		  onClick={(e) => closeMenu(e)}
		>
		  <form
			className="drawer__wrapper"
			onSubmit={(e) => {
			  e.preventDefault()
			}}
		  >
			{numberOfCartItems ? (
			  <>
				<h2 className="drawer__title">
				  Корзина:{" "}
				  <span
					className="drawer__exit"
					onClick={() =>
					  setIsBasketOpen(!isBasketOpen)
					}
				  ></span>
				</h2>
				<div className="drawer__carts-block">
				  {addedCart.map((cart, index) => {
					return (
					  <DrawerCart
						product={cart}
						key={index}
					  />
					);
				  })}
				</div>
				<div className="drawer__price">Сумма {price} ₽ </div>
				<h4 className="drawer__text">Фамилия и Имя:</h4>
				<input
				  className="drawer__input"
				  tabIndex={1}
				  name="fullName"
				  value={userInputsData.fullName.value}
				  onChange={onChangeInput}
				  style={{
					borderColor: userInputsData.fullName.isValid ? "rgba( 24, 188, 156, 1)" : "red",
				  }}
				  type="text"
				  placeholder="Фамилия Имя Отчество"
				/>
				{userInputsData.fullName.errorMessage && (
				  <p style={{ color: "red" }}>
					{userInputsData.fullName.errorMessage}
				  </p>
				)}
				<h3 className="drawer__text">Телефон:</h3>
				<input
				  style={{
					borderColor: userInputsData.phoneNumber.isValid
					  ? "rgba( 24, 188, 156, 1)"
					  : "red",
				  }}
				  className="drawer__input"
				  tabIndex={2}
				  type="text"
				  name="phoneNumber"
				  value={userInputsData.phoneNumber.value}
				  onChange={onChangeInput}
				  placeholder="+7 (___) ___-__-__"
				/>
				{userInputsData.phoneNumber.errorMessage && (
				  <p style={{ color: "red" }}>
					{userInputsData.phoneNumber.errorMessage}
				  </p>
				)}
				<h4 className="drawer__text">Город:</h4>
				<input
				  name="city"
				  className="drawer__input"
				  type="text"
				  tabIndex={3}
				  value={userInputsData.city.value}
				  onChange={onChangeInput}
				  placeholder="Москва"
				  style={{
					borderColor: userInputsData.city.isValid ? "rgba( 24, 188, 156, 1)" : "red",
				  }}
				/>
				{userInputsData.city.errorMessage && (
				  <p style={{ color: "red" }}>{userInputsData.city.errorMessage}</p>
				)}
				<div className={isShowCities ? "showed showedActive" : "showed"}>
				  {deliveryCitiesList.length > 0
					? deliveryCitiesList.map((city, index) => (
					  <div
						onClick={(e) => {
						  let newItem = { ...city, isActive: true };
						  setShowCities(false);
						  setUserInputsData((prevData) => {
							return {
							  ...prevData,
							  city: {
								...city,
								isValid: true,
								value: newItem.city
							  },
							  postBranch: {
								isValid: false,
								value: "",
								errorMessage: "Выберите номер отделения",
							  },
							}
						  });
						  setActiveCity(newItem);
						  setDeliveryCitiesList([]);
						}}
						key={index}
					  >
						{city.city + ", " + city.country}
					  </div>
					))
					: null}
				</div>

				<h4 className="drawer__text">Отделение CDEK:</h4>
				<input
				  value={userInputsData.postBranch.value}
				  name="postBranch"
				  onChange={onChangeInput}
				  className="drawer__input"
				  tabIndex={4}
				  type="text"
				  placeholder=""
				  style={{
					borderColor: userInputsData.postBranch.isValid
					  ? "rgba( 24, 188, 156, 1)"
					  : "red",
				  }}
				/>
				{userInputsData.postBranch.errorMessage && (
				  <p style={{ color: "red" }}>
					{userInputsData.postBranch.errorMessage}
				  </p>
				)}
				<div
				  className={
					isShowPickupPoints ? "showed showedActive" : "showed"
				  }
				>
				  {pickupPointsList.length > 0
					? pickupPointsList.map((department) => (
					  <div
						key={department.code}
						onClick={(e) => {
						  let newArr = {
							...userInputsData,
							postBranch: {
							  ...department,
							  isValid: true,
							  value: `${department.location.address}`,
							},
						  };
						  setShowPickupPoints(false);
						  setUserInputsData(newArr);
						  fetchDeliveryCost(department.location.postal_code);
						}}
					  >
						{department.location.address}
					  </div>
					))
					: null}
				</div>

				<h4 className="drawer__text">Комментарий:</h4>
				<input
				  tabIndex={5}
				  style={{
					borderColor: userInputsData.comment.isValid ? "rgba( 24, 188, 156, 1)" : "red",
				  }}
				  value={userInputsData.comment.value}
				  onChange={onChangeInput}
				  name="comment"
				  className="drawer__input"
				  type="text"
				  placeholder="Здесь вы можете оставить комментарий к заказу"
				/>


				<h4 className="drawer__text">Как с вами связаться</h4>
				<label className="drawer__label">
				  <input
					className="drawer__label-input"
					tabIndex={7}
					type="radio"
					defaultChecked
					onClick={(e) => setInputMessenger(e.target.value)}
					value="telegram"
					name="connection"
				  />
				  <h5 className="drawer__label-title">Telegram</h5>
				</label>
				<label className="drawer__label">
				  <input
					className="drawer__label-input"
					tabIndex={7}
					type="radio"
					onClick={(e) => setInputMessenger(e.target.value)}
					value="whatsapp"
					name="connection"
				  />
				  <h5 className="drawer__label-title">Whatsapp</h5>
				</label>
	
				<div className="drawer__text" style={{ textAlign: "right" }}>Сумма {price} ₽</div>

				<div className="drawer__text" style={{ textAlign: "right" }}>
				  Стоимость доставки: {deliveryCost} ₽
				</div>

				<div className="drawer__price" >Сума {price + deliveryCost} ₽ </div>

				{deliveryCostError && (
				  <p style={{ color: "red" }}>{deliveryCostError}</p>
				)}
				{!isValid ? (
				  <div className="drawer__error">
					<p>Заполните все поля правильно</p>
				  </div>
				) : null}
				<button className="drawer__button" tabIndex={10} onClick={(e) =>
				  onSubmitFunc(e)

				} type="submit">
				  Оформить заказ
				</button>
				{/* <p className="drawer__smallText">
				  Нажимая кнопку, вы соглашаетесь с 
				  <Link to={"/privacy-policy"} onClick={() => setIsBasketOpen(false)}>
					{" "}
					Политикой конфиденциальности
				  </Link>{" "}
				  та
				  <Link to={"/oferta"} onClick={() => setIsBasketOpen(false)}> Договором публичной оферты</Link>
				</p> */}
			  </>
			) : (
			  <div className="like__nothing">
				<h2 className="like__text">Вы еще ничего не заказали</h2>
				<button
				  aria-label="Заказать"
				  className="like__button"
				  onClick={() => setIsBasketOpen(false)}
				>
				  ЗАКАЗАТЬ
				</button>
			  </div>
			)}
		  </form>
		</section>
	  ) : (
		<SuccessModalMessage
		  setShowMessage={setShowMessage}
		  closeMenu={closeMenu}
		  status="false"
		/>
	  )}
	</>
  );
};


export default Basket;
