
export default async (message = "<b>Заявка</b>") => {
    const TOKEN = process.env.REACT_APP_TELEGRAM_TOKEN;
    const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;
    const URL_API = `https://api.telegram.org/bot${TOKEN}`;

    //preparing sending data
    const formData = new URLSearchParams();
    formData.append("chat_id", CHAT_ID);
    formData.append("text", message);
    formData.append("parse_mode", "html");

    //sending data
    fetch(`${URL_API}/sendMessage`, {
        method: "POST",
        body: formData,
    })
        .then((response) => {
            return response;
        })
        .then((response) => response.json())
        .then((data) => {
            console.log("Message sent:", data);
        })
        .catch((error) => {
            console.error("Error:", error);
        });
}
