import React, { useContext } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import {Container} from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import AddedButton from './components/AddedButton'
import Basket from './components/Basket'



const App = () => {
  // const {addedCart} = useContext(BasketContext)
  return (
    <>  
    <Header />
    <main className='py-3'>
      <Container style={{padding: 0}}>
        <Outlet />
      </Container>
    </main>
    <AddedButton/>
    <Basket/>
    <Footer />
    </>
  )
}

export default App