
import React, { useContext, useState } from 'react'
import { BasketContext } from '../../context/Context';


const AddedButton = ({}) => {
  const {addedCart,setIsBasketOpen,
    classForText,
    classAdd,totalPrice} = useContext(BasketContext)

  const add = ()=>{
    setIsBasketOpen(true)
  }

    return (
      <button
        className={`AddButton ${classAdd}`}
        style={
          addedCart.length
            ? { opacity: "1", zIndex: "3" }
            : { opacity: "0", zIndex: "-1233" }
        }
        onClick={add}
      >
        <div className={`AddButton__wrapper ${classAdd}`}>
          <div className={`${classForText}`}>{totalPrice}</div>
          <img
            width="40px"
            height="40px"
            decoding="async"
            className={classAdd}
            src={`${process.env.PUBLIC_URL}/images/shopping-bag-shopper-svgrepo-com.svg`}
            alt="button for adding carts in basket"
          />
        </div>
        <span className="header__mobile_number header__mobile_number_red">
          {addedCart.length}
        </span>
      </button>
    );
  };

export default AddedButton