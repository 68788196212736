import { Link } from "react-router-dom";

const SuccessModalMessage= ({
    setShowMessage,
    closeMenu,
  }) => {
    return (
      <div className="ModalMessage" onClick={(e) => closeMenu(e)}>
        <div className="ModalMessage_wrapper">
          <h1 className="ModalMessage_title">Заявка оформлена</h1>
          <p className="ModalMessage_description">
            Ваша заявка принята, наш менеджер свяжется с вами в ближайшее время
          </p>
          <p className="ModalMessage_text">
            Вы можете написать нам, если остались вопросы
            <a
              aria-label="Перейти в телеграм"
              className="ModalMessage_viber"
              href="https://t.me/astana22"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Телеграм{" "}
            </a>
            
            {/* <a
              aria-label="Перейти в вайбер"
              className="ModalMessage_telegram"
              href="viber://chat?number=%2B380639652780/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Viber{" "}
            </a> */}
          </p>
          <Link
            aria-label="Перейти на главную страницу"
            to={""}
            onClick={() => setShowMessage(false)}
            className=" firstSection__button ModalMessage_button"
          >
            На главную
          </Link>
        </div>
      </div>
    );
  };

  export default SuccessModalMessage