import { FaCartPlus, FaTrashAlt } from 'react-icons/fa';
import React, { useContext } from 'react'
import { BasketContext } from '../../context/Context';
import { Button } from 'react-bootstrap';

const ProductAddButton = ({
    product
}) => {

    const {
        addedCart,
        addAddedCart,
        decrement,
        increment,
        changeQuantity,
    } = useContext(BasketContext)
    return (
        (product.qtty > 0) ? (
            <div className="action">
                {addedCart.findIndex(i => i.med_id === product.med_id) <= -1 ? (
                    <button className="addtocart" onClick={(e) => addAddedCart(product)}>
                        <FaCartPlus /> В Корзину
                    </button>
                ) : (
                    <div className="quantity">
                        <button className="minus" onClick={(e) => decrement(e, product)}>
                            {addedCart.find(i => i.med_id === product.med_id).quantity <= 1 ? (
                                <FaTrashAlt style={{ display: 'flex', color: '#131313' }} />
                            ) : (
                                <span style={{ color: '#131313' }}>-</span>
                            )}
                        </button>
                        <input
                            className="number"
                            type="number"
                            value={addedCart.find(i => i.med_id === product.med_id).quantity}
                            onChange={(e) => changeQuantity(e.target.value, product)}
                        />
                        <button className="plus" onClick={(e) => increment(e, product)}>
                            +
                        </button>
                    </div>
                )}
            </div>
        ) :
            <Button
                variant={product.qtty > 0 ? 'success' : 'danger'}
                type="button"
                disabled={product.qtty <= 0}
                className="flex-grow-1 "
                style={{width: "100%", margin: "0 auto"}}
            >
                Нет в наличии
            </Button>



    )
}

export default ProductAddButton;