export default async (data)=>{
        let message = `<b>Заявка c сайта! </b>`;
            message += `
Сума: <i>${data.total}</i>
Імя замовника <i>${data.fullName}</i>
Номер телефону <i>${data.phoneNumber}</i>
Адреса на яку відправити:
<i>Город ${data.city},
Номер віділення ${data.novaPoshtaBranch}</i>
Коментар <i>${data.comment}</i>
<b>Товари</b>`;
    data.carts.forEach((element) => {
      message += `
<b>${element.med_name} </b>:
Цeна  <i>${element.price}</i>
Количиство <i>${element.quantity}</i>
`;

    });
    message += `
Способ оплаты ${data.inputPayment} 
Спосіб звязку ${data.inputMessenger}`;
    return message
}

