import React from 'react';
import ReactDOM from 'react-dom/client';
import Oferta from './screens/Oferta';
import PrivacyPolicy from './screens/privacyPolicy';

import { 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,

} from 'react-router-dom'
import './assets/styles/bootstrap.custom.css';  // Add this line
import './assets/styles/index.css';  // Add this line
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import Context from './context/Context';
import "./style.scss";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} >
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path={`/product/:id`} element={<ProductScreen />} />
      <Route path={`/oferta`} element={<Oferta />} />
      <Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Context>
    <RouterProvider router={router}/>
    </Context>
  </React.StrictMode>
);


reportWebVitals();
