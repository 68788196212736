import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    // Вызовите scrollToTop при загрузке страницы
    scrollToTop();
  }, []);

  return (
    <strong className="Oferta">
      Политика конфиденциальности
      <br />
      Сайт https://aptekalandysh.ru/ принадлежит Mister Gifter, который является контролером
      для ваших персональных данных.
      <br />
      <br />
      Мы используем эту Политику конфиденциальности, которая определяет, как мы
      обрабатываем информацию, собранную https://aptekalandysh.ru/, а также
      причины, по которым мы должны собирать определенные персональные данные о вас. Поэтому вы
      должны прочитать эту Политику конфиденциальности перед использованием сайта
      https://aptekalandysh.ru/.
      <br />
      <br />
      Мы заботимся о ваших персональных данных и гарантируем их конфиденциальность и
      безопасность.
      <br />
      <br />
      Персональная информация, которую мы собираем:
      <br />
      при посещении https://aptekalandysh.ru/, мы автоматически собираем определенную
      информацию о вашем устройстве, включая информацию о вашем веб-браузере,
      IP-адресе, часовом поясе и т.д., а также некоторые из установленных файлов cookies на
      вашем устройстве. Кроме того, когда вы просматриваете Сайт, мы собираем
      информацию об отдельных страницах или продуктах, которые вы просматриваете, о том,
      какие сайты или поисковые запросы направили вас на Сайт и как вы взаимодействуете с
      Сайтом. Мы называем эту автоматически собранную информацию «Информация о
      устройстве». Кроме того, мы можем собирать персональные данные, которые вы предоставляете нам
      (включая имя, фамилию, адрес, платежную информацию и т.д.) во время
      регистрации, чтобы иметь возможность выполнить соглашение.
      <br />
      <br />
      Почему мы обрабатываем ваши данные?
      <br />
      Нашим главным приоритетом является безопасность клиентов, поэтому мы должны
      обрабатывать минимальный объем данных пользователя: ровно столько, сколько
      необходимо для обслуживания сайта. Информация, которая собирается автоматически,
      используется только для выявления потенциальных нарушений и получения
      статистической информации об использовании Сайта. Эта статистическая информация
      не собирается таким образом, чтобы можно было идентифицировать конкретного пользователя
      системы.
      <br />
      <br />
      Вы можете посещать Сайт, не сообщая нам, кто вы, и не раскрывая
      никакой информации, с помощью которой кто-либо мог бы идентифицировать вас как
      конкретное лицо. Однако, если вы хотите использовать некоторые функции
      сайта, хотите получать нашу рассылку новостей или другие сведения,
      заполнив форму, вы можете предоставить нам персональные данные, такие как ваш адрес
      электронной почты, имя, фамилию, место жительства, организацию,
      телефон. Вы можете не предоставлять нам персональные данные, но в этом случае вы
      не сможете воспользоваться определенными функциями Сайта. Например, вы не сможете
      получать наши рассылки новостей или связываться с нами напрямую через
      Сайт. Пользователи, которые не уверены, какая информация обязательна, могут
      связаться с нами по адресу gifter.in.ua@gmail.com.
      <br />
      <br />
      Ваши права:
      <br />
      Если вы являетесь резидентом Европы, у вас есть следующие права, связанные с вашими
      персональными данными:
      <br />
      <br />
      Право получения информации.
      <br />
      Право доступа.
      <br />
      Право на внесение исправлений.
      <br />
      Право на уничтожение данных.
      <br />
      Право ограничивать обработку.
      <br />
      Право на перенос данных.
      <br />
      Право на восстановление.
      <br />
      Права в отношении автоматизированного принятия решений и профилирования.
      <br />
      Если вы хотите воспользоваться этим правом, свяжитесь с нами, используя
      указанную ниже контактную информацию.
      <br />
      <br />
      Кроме того, если вы являетесь резидентом Европы, мы отмечаем, что мы обрабатываем
      вашу информацию для выполнения контрактов, которые могут быть заключены с вами
      (например, если вы делаете заказ через Сайт), или иным образом для
      преследования наших законных деловых интересов, перечисленных выше. Кроме
      того, обратите внимание, что ваша информация может быть передана за пределы
      Европы, включая Канаду и США.
      <br />
      <br />
      Ссылки на другие сайты:
      <br />
      Наш Сайт может содержать ссылки на другие сайты, которые нам не принадлежат и не
      контролируются нами. Обратите внимание, что мы не несем ответственности за
      политику конфиденциальности таких сайтов или третьих лиц. Мы рекомендуем
      вам проверять, когда вы покидаете наш Сайт, и читать заявления о
      конфиденциальности каждого сайта, который может собирать персональную информацию.
      <br />
      <br />
      Информационная безопасность:
      <br />
      Мы защищаем информацию, которую вы предоставляете, на компьютерных серверах в
      контролируемой безопасной среде, защищенной от несанкционированного
      доступа, использования или раскрытия. Мы принимаем разумные административные,
      технические и физические меры безопасности для защиты от несанкционированного
      доступа, использования, изменения и раскрытия персональных данных, находящихся
      под его контролем и хранением. Однако передача данных через Интернет или
      беспроводную сеть не может быть гарантирована.
      <br />
      <br />
      Юридическое раскрытие:
      <br />
      Мы будем раскрывать любую информацию, которую мы собираем, используем
      или получаем, если это требуется или разрешено законом, например,
      в соответствии с повесткой в суд или аналогичным судебным процессом, и когда
      мы добросовестно считаем, что раскрытие информации необходимо для защиты наших
      прав, вашей безопасности или безопасности других лиц, расследования мошенничества или
      ответа на запрос правительства.
      <br />
      <br />
      Контактная информация:
      <br />
      Если вы хотите связаться с нами, чтобы узнать больше о этой Политике,
      или хотите связаться с нами по любому вопросу, касающемуся
      индивидуальных прав и вашей Персональной информации, вы можете отправить
      письмо на адрес dmukayev@gmail.com.
      <br />
    </strong>
  );
};

export default PrivacyPolicy;
